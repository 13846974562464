import React from 'react'
import ReactMarkdown from 'react-markdown'
import { graphql } from 'gatsby'

import Seo from '../components/SEO/Seo'

const articleTemplate = ( { data: { strapiArticle }, location } ) => {

    return (
        <>
            <Seo
                title={ strapiArticle?.seo?.metaTitle }
                description={ strapiArticle?.seo?.metaDescription }
                keywords={ strapiArticle?.seo?.keywords }
                pathname={ location.pathname } />

            <div className="article">

                <div className="article__wrapper wrapper">
                    <h1 className="article__heading">{ strapiArticle.articeTitle }</h1>
                    <ReactMarkdown
                        className="article__article-body"
                        linkTarget="_blank"
                        children={ strapiArticle.articleContent.data.articleContent } />
                </div>
            </div>
        </>
    )
}

export default articleTemplate

export const query = graphql`
    query ArticleQuery($id: Int!) {
        strapiArticle(strapi_id: {eq: $id}) {
            articeTitle
            articleSlug
            articleContent {
                data {
                    articleContent
                }
            }
            seo {
                metaTitle
                metaDescription
                keywords
            }
        }
    }
`